import React, { useState } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"

function createMarkup(markup) {
  return { __html: markup }
}

const NewsletterSubscriptionForm = () => {
  const [flashMessage, setFlashMessage] = useState("")
  const [flashClass, setFlashClass] = useState("form__result")

  const [email, setEmail] = useState("")
  const handleSubmit = e => {
    e.preventDefault()
    addToMailchimp(email, []) // listFields are optional if you are only capturing the email address.
      .then(data => {
        if (data.result === "error") setFlashClass("form__result error")
        setFlashMessage(data.msg)
      })
  }

  const handleChange = setState => {
    return e => {
      setState(e.target.value)
    }
  }
  return (
    <div className="col col__6">
      <h1 className="newsletter__title title">Newsletter</h1>
      <p className="newsletter__desc">
        Sign up for our monthly newsletter for discussions on trending
        technology topics and for the latest MangoChango news.
      </p>
      <p
        className={`${flashClass}`}
        dangerouslySetInnerHTML={createMarkup(flashMessage)}
      ></p>
      <form className="newsletter__form" onSubmit={handleSubmit}>
        <input
          className="newsletter__form-input"
          type="email"
          placeholder="Your email"
          value={email}
          onChange={handleChange(setEmail)}
        />

        <button className="newsletter__form-button button button--medium button--secondary button--icon-end">
          <span>Subscribe</span>
          <span className="icon material-icons">arrow_right_alt</span>
        </button>
      </form>
    </div>
  )
}

export default NewsletterSubscriptionForm
