/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Link } from "gatsby"

import PropTypes from "prop-types"
import mangochango_logo from "../imgs/mangochango_logo.png"
import ch_logo from "../imgs/ch_logo.png"
import icon_facebook_dark from "../imgs/icon_facebook-dark.svg"
import icon_linkedin_dark from "../imgs/icon_linkedin-dark.svg"
import icon_twitter_dark from "../imgs/icon_twitter-dark.svg"
import { StaticQuery, graphql } from "gatsby"

import "../css/styles.css"
import "../css/custom.css"
import PastNewsletters from "./past-newsletters"
import NewsletterSubscriptionForm from "./newsletter-subscription-form"

const LikeWhatYouSee = ({ hideOn, location }) => {
  const currentPath = location.pathname
  if (hideOn.indexOf(currentPath) > -1) return null

  return (
    <div className="row">
      <div className="col col__12">
        <div className="footer__like">
          <p className="footer__like-title">Like What You See?</p>
          <span className="footer__like-line" />
          <Link
            to="/contact-us"
            className="button button--white button--large button--icon-end"
          >
            Let's Talk
            <span className="icon material-icons">arrow_right_alt</span>
          </Link>
        </div>
      </div>
    </div>
  )
}

const Layout = ({ children, isHome, location }) => {
  const getHomeHref = src => {
    if (isHome) return src
    return `/${src}`
  }

  return (
    <div>
      {/* Header */}
      <header id="main-header" className="header">
        <div className="container">
          <div className="row">
            <div className="header__container col col__12">
              <div className="header__logo">
                <a href="/" title="Homepage">
                  <h1 className="screen-reader-text">Mango Chango</h1>
                  <img src={mangochango_logo} alt="Mango Chango" />
                </a>
              </div>
              <div className="header__menu">
                <ul className="header__menu-container">
                  <li className="header__menu-item">
                    <a href={getHomeHref("#whatwedo")}>What We Do</a>
                  </li>
                  <li className="header__menu-item">
                    <a href={getHomeHref("#whoweare")}>Who We Are</a>
                  </li>
                  <li className="header__menu-item">
                    <a href={getHomeHref("#howwedoit")}>How We Do It</a>
                  </li>
                  <li className="header__menu-item header__submenu">
                    <Link to="#">
                      Insights
                      <span className="icon material-icons">
                        arrow_drop_down
                      </span>
                    </Link>
                    <ul className="header__submenu-box">
                      <li className="header__submenu-item">
                        <Link to="/case_studies">Case Studies</Link>
                      </li>
                      <li className="header__submenu-item">
                        <Link to="/media">Media</Link>
                      </li>
                      <li className="header__submenu-item">
                        <Link to="/newsletters">Newsletters</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
                <Link
                  to={`/contact-us`}
                  className="link__contact button button--action button--outline button--icon-end"
                >
                  Contact Us
                  <span className="icon material-icons">arrow_right_alt</span>
                </Link>
              </div>
              <span className="header__menu-overlay hide-on-laptop"></span>
              <button className="header__menu-button hide-on-laptop">
                <span className="icon material-icons">menu</span>
              </button>
            </div>
          </div>
        </div>
      </header>
      {/* Header */}
      <div id="main-content">
        {/* Children*/}
        {children}
        {/*/Children*/}
        {/*Newsletters*/}
        <section className="newsletter">
          <div className="container">
            <LikeWhatYouSee
              location={location}
              hideOn={["/contact-us"]}
            ></LikeWhatYouSee>
            <div className="row">
              <NewsletterSubscriptionForm></NewsletterSubscriptionForm>
              <StaticQuery
                query={graphql`
                  query pastNewslettersQuery {
                    relatedNewsletters: allWordpressWpNewsletters(
                      sort: { fields: date, order: DESC }
                      limit: 3
                    ) {
                      edges {
                        node {
                          id
                          slug
                          date
                          title
                        }
                      }
                    }
                  }
                `}
                render={data => {
                  const newsletters = data.relatedNewsletters.edges
                  if (newsletters.length === 0) {
                    return null
                  }
                  return (
                    <PastNewsletters
                      title="Past Newsletters"
                      newsletters={newsletters}
                    ></PastNewsletters>
                  )
                }}
              />
            </div>
          </div>
        </section>
        {/*Newsletters*/}
        {/*Footer*/}
        <footer id="contact" className="footer">
          <div className="row mobile">
            <div className="col col__5 col__m12 subsidiary">
              <div className="subsidiary__text">
                A{" "}
                <a
                  className="subsidiary__link"
                  href="https://www.commandhound.com"
                >
                  CommandHound
                </a>{" "}
                Company
              </div>
              <div className="subsidiary__line"></div>
              <a href="https://www.commandhound.com">
                <div className="subsidiary__img">
                  <img src={ch_logo} alt="" />
                </div>
              </a>
            </div>
          </div>
          <div className="footer__top container">
            <div className="row">
              <div className="footer__intro col col__4  col__m12">
                <h2 className="footer__title">
                  Make Sure Things Get Done <span>&reg;</span>{" "}
                </h2>
                <p>
                  MangoChango is a Dallas-based technology and software
                  development consultancy helping clients realize their digital
                  transformation goals.
                </p>
              </div>

              <div className="footer__quicklinks col col__3 col__m4">
                <h2 className="footer__title">Quick Links</h2>
                <ul>
                  <li>
                    <a href={getHomeHref("#whatwedo")}>What We Do</a>
                  </li>
                  <li>
                    <a href={getHomeHref("#whoweare")}>Who We Are</a>
                  </li>
                  <li>
                    <a href={getHomeHref("#howwedoit")}>How We Do It</a>
                  </li>
                </ul>
              </div>
              <div className="col col__5 col__m8">
                <h2 className="footer__title footer__title--center">Offices</h2>
                <div className="row">
                  <div className="footer__office col col__6">
                    <h3 className="footer__subtitle">Dallas</h3>
                    <p>
                    4849 Greenville Avenue
                      <br />
                      Suite 100 <br />
                      Dallas, TX 75206
                    </p>
                    <p>
                      <a
                        className="footer__link footer__link--special"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://maps.app.goo.gl/LuuKMF8XFJsAFq6u8"
                      >
                        <span className="icon material-icons">location_on</span>
                        Map & Directions
                      </a>
                    </p>
                    <p>
                      <a className="footer__link" href="tel:+12147313113">
                        <span className="icon material-icons">smartphone</span>
                        +1.214.731.3113
                      </a>
                    </p>
                  </div>
                  <div className="footer__office col col__6">
                    <h3 className="footer__subtitle">Guatemala</h3>
                    <p>
                    Europlaza World Business Center<br />
                    Tower 2, Suite 1402 <br />
                    Guatemala City
                    </p>
                    <p>
                      <a
                        className="footer__link footer__link--special"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://maps.app.goo.gl/RCdDmcEVQgXBEKqA7"
                      >
                        <span className="icon material-icons">location_on</span>
                        Map & Directions
                      </a>
                    </p>
                    <p>
                      <a className="footer__link" href="tel:+50236463797">
                        <span className="icon material-icons">smartphone</span>
                        +502. 2293.3662
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row non-mobile row-sub">
              <div className="col col__5 col__m12 subsidiary">
                <div className="subsidiary__text">
                  A{" "}
                  <a
                    className="subsidiary__link"
                    href="https://www.commandhound.com"
                  >
                    CommandHound
                  </a>{" "}
                  Company
                </div>
                <div className="subsidiary__line"></div>
                <a href="https://www.commandhound.com">
                  <div className="subsidiary__img">
                    <img src={ch_logo} alt="" />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="footer__bottom">
            <div className="container">
              <div className="footer__bottom-container row">
                <p>© 2020 MangoChango LLC. All Rights Reserved.</p>
                <ul className="footer__social">
                  <li>
                    <a href="https://twitter.com/TheMangoChango">
                      <img src={icon_twitter_dark} alt="Twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/themangochango/">
                      <img src={icon_facebook_dark} alt="Facebook" />
                    </a>
                  </li>
                  {/*  <li>
                    <a href>
                      <img src={icon_instagram_dark} alt="Instagram" />
                    </a>
                </li>*/}
                  <li>
                    <a href="https://www.linkedin.com/company/mangochango/">
                      <img src={icon_linkedin_dark} alt="Linkedin" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
        {/*Footer*/}
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
}

export default Layout
