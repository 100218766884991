import React from "react"
import { getMonthText } from "../utils"
import { Link } from "gatsby"
const NewsletterCard = ({ newsletter }) => {
  const { title, slug, date } = newsletter
  const ndate = new Date(date)
  return (
    <article key={slug} className="newsletter__item">
      <Link to={`/newsletters/${slug}`}>
        <p className="newsletter__date">
          <span className="newsletter__date-month">
            {getMonthText(ndate.getMonth())}
          </span>
          <span className="newsletter__date-year">{ndate.getFullYear()}</span>
        </p>
        <h3 className="newsletter__item-title">{title}</h3>
      </Link>
    </article>
  )
}

export default NewsletterCard
