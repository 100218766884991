import React from "react"
import NewsletterCard from "../components/newsletter-card"
import { Link } from "gatsby"
const PastNewsletters = ({ title, newsletters, limit }) => {
  return (
    <div className="col col__6">
      <h2 className="newsletter__subtitle subtitle">{title}</h2>
      {newsletters.map(({ node }) => (
        <NewsletterCard key={node.slug} newsletter={node}></NewsletterCard>
      ))}
      <div className="button__group button__group--end">
        <Link to="/newsletters" className="button button--secondary">
          View More
        </Link>
      </div>
    </div>
  )
}

export default PastNewsletters
